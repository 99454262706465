export default function useAvailableLocalesByCountry() {
    const locales = useAvailableLocales();

    type LocaleByCountry = {
        language: string;
        locale: string;
    };
    const grouped: Record<string, LocaleByCountry[]> = {};

    for (const locale of locales) {
        const [country, language] = locale?.split('-');

        if (!grouped[country]) {
            grouped[country] = [];
        }

        grouped[country].push({
            language,
            locale,
        });
    }

    return grouped;
}
